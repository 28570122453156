import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TreeModule} from "angular-tree-component";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";




@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    TreeModule.forRoot(),
    PerfectScrollbarModule,
    NgbModule
  ],
  exports: [
    TreeModule,
    PerfectScrollbarModule,
    NgbModule
  ]
})
export class SharedModule { }
