import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {SharedModule} from "./modules/shared/shared.module";
import { HeaderComponent } from "./modules/shared/components/header/header.component";
import { SidebarComponent } from "./modules/main/components/sidebar/sidebar.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {InlineSVGModule} from "ng-inline-svg";
import {TreeModule} from "angular-tree-component";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import { APP_BASE_HREF } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    InlineSVGModule.forRoot(),
    TreeModule.forRoot(),
    PerfectScrollbarModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/public' },
    { provide: LOCALE_ID, useValue: "ru-ru" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
